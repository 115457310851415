
import { defineComponent, ref, shallowRef, computed } from "vue";
import type { PropType } from "vue";
import { message } from "ant-design-vue";
import {
  SiccoCheckingUploadType,
  BMBSClaimUploadResult,
} from "@/views/Checking/SICCOChecking/types";
import {
  bmbsClaimCheck,
  bmbsClaimSave,
  bonusAdjustmentCheck,
  bonusAdjustmentSave,
  bmbsForceSubmit
} from "@/API/checking/checkingSICCO";

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<SiccoCheckingUploadType>,
      default: SiccoCheckingUploadType.BonusAdjustment,
    },
    programCode: {
      type: String,
      default: "",
    },
    offerType: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const title = computed(() => {
      switch (props.type) {
        case SiccoCheckingUploadType.BonusAdjustment:
          return "Import Adjustment";
        case SiccoCheckingUploadType.BMBSClaim:
          return "Import BMBS Claim";
        default:
          return "";
      }
    });

    const showUpload = ref(true);
    const percent = ref(0);
    const uploadFlag = ref(true);
    const file = ref();
    const showResult = ref(false);
    const successedNum = ref(0);
    const failedNum = ref(0);
    const resultData = shallowRef<BMBSClaimUploadResult[]>([]);
    const forceSubmitBtnVisible = ref(false);
    const forceSubmitModalVisible = ref(false);
    const submitReason = ref('')

    const handleClose = () => {
      showUpload.value = true;
      file.value = undefined;
      uploadFlag.value = false;
      percent.value = 0;
      showResult.value = false;
      successedNum.value = 0;
      failedNum.value = 0;
      resultData.value = [];
      emit("update:show", false);
      forceSubmitBtnVisible.value = false;
    };
    const uploadRequest = (source: any) => {
      showUpload.value = false;
      if (source.file) {
        uploadFlag.value = false;
      } else {
        uploadFlag.value = true;
      }
      file.value = source.file;
    };
    const handleImport = async () => {
      try {
        if (props.type === SiccoCheckingUploadType.BMBSClaim) {
          const data = await bmbsClaimCheck(
            props.programCode,
            props.offerType,
            file.value,
            {
              onUploadProgress: (progressEvent: any) => {
                if (progressEvent.lengthComputable) {
                  percent.value =
                    ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                }
              },
            }
          );
          successedNum.value = data.success.length;
          failedNum.value = data.failed.length;
          resultData.value = data.failed.concat(data.success);
          showResult.value = true;
          forceSubmitBtnVisible.value = 'displayForceSubmitButton' in data
        } else {
          const data = await bonusAdjustmentCheck(
            props.programCode,
            props.offerType,
            file.value,
            {
              onUploadProgress: (progressEvent: any) => {
                if (progressEvent.lengthComputable) {
                  percent.value =
                    ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                }
              },
            }
          );
          successedNum.value = data.success.length;
          failedNum.value = data.failed.length;
          resultData.value = data.failed.concat(data.success);
          showResult.value = true;
        }
      } catch (e) {
        showUpload.value = true;
        uploadFlag.value = true;
        percent.value = 0;
        showResult.value = false;
        successedNum.value = 0;
        failedNum.value = 0;
        resultData.value = [];
      }
    };

    const columns = computed(() => {
      if (props.type === SiccoCheckingUploadType.BMBSClaim) {
        return [
          {
            title: "Program Code",
            dataIndex: "programCode",
          },
          {
            title: "Dealer Name",
            dataIndex: "dealerName",
          },
          {
            title: "Dealer Code",
            dataIndex: "dealerCode",
          },
          {
            title: "VIN",
            dataIndex: "vin",
          },
          {
            title: "Check Result",
            dataIndex: "checkResult",
          },
          {
            title: "Final Bonus",
            dataIndex: "finalBonus",
          },
          {
            title: "Failed Reason",
            dataIndex: "errorMsg",
          },
        ];
      } else {
        return [
          {
            title: "Dealer Code",
            dataIndex: "dealerCode",
          },
          {
            title: "VIN",
            dataIndex: "vinNo",
          },
          {
            title: "Program Code",
            dataIndex: "programCode",
          },
          {
            title: "Adjustment Type",
            dataIndex: "adjustmentType",
          },
          {
            title: "Comments",
            dataIndex: "adjustmentComments",
          },
          {
            title: "Failed Reason",
            dataIndex: "errorMsg",
          },
        ];
      }
    });

    const handleSubmit = async () => {
      if (props.type === SiccoCheckingUploadType.BMBSClaim) {
        await bmbsClaimSave(props.programCode, props.offerType, file.value);
      } else {
        await bonusAdjustmentSave(
          props.programCode,
          props.offerType,
          file.value
        );
      }
      message.success("Submit Successfully!");
      handleClose();
    };

    const spinWrapper = document.querySelector(
      ".spingWrapper .ant-spin-container"
    );

    const handleToForceSubmit = () => {
      forceSubmitModalVisible.value = true;
    }

    const handleCancel = () => {
      submitReason.value = '';
    }

    const handleForceSubmit = async () => {
      await bmbsForceSubmit(props.programCode, props.offerType, file.value, submitReason.value);
      message.success("Submit Successfully!");
      submitReason.value = '';
      forceSubmitModalVisible.value = false;
      handleClose();
    }

    return {
      SiccoCheckingUploadType,
      title,
      file,
      handleClose,
      uploadRequest,
      handleImport,
      showUpload,
      uploadFlag,
      percent,
      showResult,
      successedNum,
      failedNum,
      columns,
      resultData,
      handleSubmit,
      spinWrapper,
      forceSubmitBtnVisible,
      forceSubmitModalVisible,
      submitReason,
      handleToForceSubmit,
      handleCancel,
      handleForceSubmit
    };
  },
});
